<template>
  <div class="pres_iva_gral" v-if="pdat">
    <div class="cab" style="width:125px">IVA General</div>
    <v-sheet style="width:125px" v-bind="$cfg.styles.marco">
      <v-select
        v-model="pdat.iva_general.value"
        v-bind="$select"
        label="Iva General"
        :items="get_ivas"
        :disabled="!is_edit"
        item-value="id"
        item-text="label"
      ></v-select>
    </v-sheet>
  </div>
</template>

<script>
export default {
  props: {
    pdat: { type: Object, default: null },
    is_edit: { type: Boolean, default: false }
  },

  computed: {
    get_ivas() {
      return [
        { id: "21", label: "21%" },
        { id: "10", label: "10%" },
        { id: "8", label: "8%" },
        { id: "4", label: "4%" },
        { id: "3", label: "3%" },
        { id: "0", label: "0%" }
      ];
    }
  }
};
</script>
